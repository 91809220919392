import React from "react";
import { makeStyles, TextField, Grid } from "@material-ui/core";
import {Field } from "formik";
import NumberFormat from "react-number-format";
import { i18n } from "../../translate/i18n";

const useStyles = makeStyles((theme) => ({
  fullWidth: {
    width: "100%",
  },
  control: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
}));

function SchedulesForm({ schedules, setFieldValue, errors, touched, showOutOfHoursMessage }) {
  const classes = useStyles();

  // Función para manejar el formato de la hora y asegurar que los valores estén correctos
  const formatTime = (time) => {
    if (!time) return "";
    return time.replace(/(\d{2})(\d{2})/, "$1:$2"); // Convierte HHmm a HH:mm
  };

  const handleTimeChange = (index, field, value) => {
    const newSchedules = [...schedules];
    newSchedules[index][field] = formatTime(value); // Formatea la hora
    setFieldValue("schedules", newSchedules); // Actualiza el valor en el padre
  };

  return (
    <Grid container spacing={4}>
      {schedules.map((item, index) => (
        <Grid key={index} container item xs={12} md={6}>
          <Grid className={classes.control} item xs={12}>
            <TextField
              label="Día"
              value={item.weekday}
              disabled
              variant="outlined"
              className={classes.fullWidth}
              margin="dense"
            />
          </Grid>
          <Grid item xs={12} md={6} className={classes.control}>
            <NumberFormat
              label="Hora Inicial"
              value={item.startTime}
              customInput={TextField}
              format="##:##"
              className={classes.fullWidth}
              variant="outlined"
              margin="dense"
              onValueChange={({ value }) =>
                handleTimeChange(index, "startTime", value)
              }
            />
          </Grid>
          <Grid item xs={12} md={6} className={classes.control}>
            <NumberFormat
              label="Hora Final"
              value={item.endTime}
              customInput={TextField}
              format="##:##"
              className={classes.fullWidth}
              variant="outlined"
              margin="dense"
              onValueChange={({ value }) =>
                handleTimeChange(index, "endTime", value)
              }
            />
          </Grid>
        </Grid>
      ))}
      
      {showOutOfHoursMessage && ( // Renderizado condicional
          <Grid container  xs={12} md={6} item>
            <Field
              as={TextField}
              label={i18n.t("queueModal.form.outOfHoursMessage")}
              type="text"
              multiline
              rows={4}
              fullWidth
              name="outOfHoursMessage"
              error={touched.outOfHoursMessage && Boolean(errors.outOfHoursMessage)}
              helperText={touched.outOfHoursMessage && errors.outOfHoursMessage}
              variant="outlined"
              margin="dense"
              className={classes.control}
              InputLabelProps={{
                className: classes.label
              }}
            />
          </Grid> 
      )}     
    </Grid>
  );
}

export default SchedulesForm;
