import React, { useState, useEffect, useRef, useContext, useCallback, useMemo } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";
import { head, isArray } from "lodash";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Chip from "@material-ui/core/Chip";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import SchedulesForm from "../SchedulesForm";

import { i18n } from "../../translate/i18n";
import moment from "moment";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import {
  Box,
  Grid,
  Tab,
  Tabs,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { AuthContext } from "../../context/Auth/AuthContext";
import ConfirmationModal from "../ConfirmationModal";

const deleteMedia = async (campaign, setAttachment, setCampaign, attachmentFile, toastSuccess, api) => {
  if (attachmentFile.current) {
    setAttachment(null);
    attachmentFile.current.value = null;
  }

  if (campaign.mediaPath) {
    await api.delete(`/campaigns/${campaign.id}/media-upload`);
    setCampaign(prev => ({ ...prev, mediaPath: null, mediaName: null }));
    toastSuccess(i18n.t("campaigns.toasts.deleted"));
  }
};

// Función para validar el formato de hora
const isValidTime = (time) => {
  const timeRegex = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/; // Formato HH:MM
  return timeRegex.test(time);
};

// Formatear los horarios para el envío al backend
const formatSchedules = (schedules) => {
  return schedules
    .filter((schedule) => schedule.startTime && schedule.endTime)
    .map((schedule) => ({
      ...schedule,
      startTime: isValidTime(schedule.startTime) ? schedule.startTime : null,
      endTime: isValidTime(schedule.endTime) ? schedule.endTime : null,
    }));
};

const processCampaignData = (values) => {
  const formattedSchedules = formatSchedules(values.schedules);

  let utcOffset = 0;
  const calculatedOffset = moment().utcOffset();
  if (!isNaN(calculatedOffset)) {
    utcOffset = calculatedOffset;
  }

  return {
    ...values,  // Se envía todo el objeto 'values' directamente
    scheduledAt: values.scheduledAt
      ? moment(values.scheduledAt).utc().format("YYYY-MM-DDTHH:mm:ss")
      : null,
    schedules: formattedSchedules,
    utcOffset,
  };
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  dialogContent: {
    overflowY: "scroll",
    ...theme.scrollbarStyles,
    maxHeight: "calc(100vh - 250px)", // Ajustar la altura máxima para el contenido del modal
  },  
  textField: {
    marginRight: theme.spacing(1),
    flex: 1,
  },
  btnWrapper: {
    position: "relative",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  tabs: {
    background: theme.mode === "light" ? "#f2f2f2" : "#7f7f7f",
    border: "1px solid #e6e6e6",
    borderRadius: 2,
  },
}));

const CampaignModal = ({
  open,
  onClose,
  campaignId,
  initialValues,
  onSave,
  resetPagination,
}) => {
  const classes = useStyles();
  const isMounted = useRef(true);
  const { user } = useContext(AuthContext);
  const { companyId } = user;

  const initialSchedules = () => {
    const daysOfWeek = [
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
      "sunday",
    ];
    return daysOfWeek.map((day) => ({
      weekday: i18n.t(`queueModal.weekdays.${day}`),
      weekdayEn: day,
      startTime: "",
      endTime: "",
    }));
  };

  const initialState = {
    name: null,
    message1: null,
    message2: null,
    message3: null,
    message4: null,
    message5: null,
    scheduledAt: "",
    whatsappIds: null,
    contactListId: null,
    companyId,
    queueId: null,
    queueOptions: false,
    messageInterval: 0,
    longerIntervalAfter: 0,
    greaterInterval: 0,
    schedules: initialSchedules(),
  };

  const [campaign, setCampaign] = useState(initialState);
  const [whatsapps, setWhatsapps] = useState([]);
  const [selectedWhatsappIds, setSelectedWhatsappIds] = useState([]);
  const [contactLists, setContactLists] = useState([]);
  const [queues, setQueues] = useState([]);
  const [tab, setTab] = useState(0);
  const [messageTab, setMessageTab] = useState(0);
  const [attachment, setAttachment] = useState(null);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [campaignEditable, setCampaignEditable] = useState(true);
  const [showQueueOptions, setShowQueueOptions] = useState(false);
  const attachmentFile = useRef(null);

  useEffect(() => {
    if (open) {
      setTab(0); // Resetea la pestaña a la primera cuando se abra el modal
    }
  }, [open]);

  useEffect(() => {
    if (initialValues) {
      const updatedSchedules = initialSchedules().map((day) => {
        const foundSchedule = initialValues.schedules?.find(
          (s) => s.weekdayEn === day.weekdayEn
        );
        return foundSchedule
          ? { ...foundSchedule, weekday: i18n.t(`queueModal.weekdays.${day.weekdayEn}`) }
          : day;
      });

      setCampaign((prevState) => ({
        ...prevState,
        ...initialValues,
        schedules: updatedSchedules,
      }));
    }
  }, [initialValues]);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const fetchCampaignData = useCallback(async (campaignId, companyId) => {
    const [contactListsRes, whatsappsRes, queuesRes] = await Promise.all([
      api.get(`/contact-lists/list`, { params: { companyId } }),
      api.get(`/whatsapp/connected`, { params: { companyId } }),
      api.get(`/queue`, { params: { companyId } }),
    ]);

    return {
      contactLists: contactListsRes.data,
      whatsapps: whatsappsRes.data,
      queues: queuesRes.data,
    };
  }, []);

  useEffect(() => {
    if (!isMounted.current || !open) return;

    const fetchData = async () => {
      try {
        const { contactLists, whatsapps, queues } = await fetchCampaignData(
          campaignId,
          companyId
        );
        setContactLists(contactLists);
        setWhatsapps(whatsapps);
        setQueues(queues);

        if (campaignId) {
          const { data } = await api.get(`/campaigns/${campaignId}`);
          setCampaign((prev) => ({
            ...prev,
            ...data,
            scheduledAt: data.scheduledAt
              ? moment(data.scheduledAt).format("YYYY-MM-DDTHH:mm")
              : "",
            queueOptions: data.queueOptions || false,
          }));
          setSelectedWhatsappIds(data.whatsapps || []);
        }
      } catch (err) {
        toastError(err);
      }
    };

    fetchData();
  }, [campaignId, open, companyId, fetchCampaignData]);

  useEffect(() => {
    if (!campaignId) {
      setCampaignEditable(true);
      setCampaign(initialState);
    } else {
      const now = moment();
      const scheduledAt = moment(campaign.scheduledAt);
      setCampaignEditable(
        campaign.status === "INATIVA" ||
          (campaign.status === "PROGRAMADA" &&
            scheduledAt.diff(now, "hour") > 1)
      );
    }
  }, [campaignId, campaign.scheduledAt]);

  const handleClose = () => {
    onClose();
    setCampaign(initialState);
    setSelectedWhatsappIds([]);
    setCampaignEditable(true);
  };

  const handleSaveCampaign = async (values) => {
    try {
      const dataValues = processCampaignData(
        values,
        selectedWhatsappIds
      );
      console.log("Datos enviados:", dataValues);

      if (campaignId) {
        await api.put(`/campaigns/${campaignId}`, dataValues);
        if (attachment) {
          const formData = new FormData();
          formData.append("file", attachment);
          await api.post(`/campaigns/${campaignId}/media-upload`, formData);
        }
      } else {
        const { data } = await api.post("/campaigns", dataValues);
        if (attachment) {
          const formData = new FormData();
          formData.append("file", attachment);
          await api.post(`/campaigns/${data.id}/media-upload`, formData);
        }
        if (onSave) onSave(data);
      }

      handleClose();
      toast.success(i18n.t("campaigns.toasts.success"));
    } catch (err) {
      console.error("Error al enviar el formulario:", err);
      toastError(err);
    }
  };

  const renderMessageField = (identifier) => {
    return (
      <Field
        as={TextField}
        id={identifier}
        name={identifier}
        fullWidth
        rows={5}
        label={i18n.t(`campaigns.dialog.form.${identifier}`)}
        placeholder={i18n.t("campaigns.dialog.form.messagePlaceholder")}
        multiline={true}
        variant="outlined"
        helperText="Utilize variáveis como {nome}, {numero}, {email} ou defina variáveis personalizadas."
        disabled={!campaignEditable && campaign.status !== "CANCELADA"}
      />
    );
  };

  const cancelCampaign = async () => {
    try {
      await api.post(`/campaigns/${campaign.id}/cancel`);
      toast.success(i18n.t("campaigns.toasts.cancel"));
      setCampaign((prev) => ({ ...prev, status: "CANCELADA" }));
      resetPagination();
    } catch (err) {
      toast.error(err.message);
    }
  };

  const restartCampaign = async () => {
    try {
      await api.post(`/campaigns/${campaign.id}/restart`);
      toast.success(i18n.t("campaigns.toasts.restart"));
      setCampaign((prev) => ({ ...prev, status: "EM_ANDAMENTO" }));
      resetPagination();
    } catch (err) {
      toast.error(err.message);
    }
  };
  const initialFormikValues = useMemo(() => {
    return {
      ...campaign,
      schedules: initialSchedules().map((day) => {
        const foundSchedule = campaign.schedules.find(
          (s) => s.weekdayEn === day.weekdayEn
        );
        return foundSchedule ? { ...day, ...foundSchedule } : day;
      }),
    };
  }, [campaign]);
console.log("initialFormikValuesCampaign:", initialFormikValues);
  return (
    <div className={classes.root}>
      <ConfirmationModal
        title={i18n.t("campaigns.confirmationModal.deleteTitle")}
        open={confirmationOpen}
        onClose={() => setConfirmationOpen(false)}
        onConfirm={() =>
          deleteMedia(campaign, setAttachment, setCampaign, attachmentFile)
        }
      >
        {i18n.t("campaigns.confirmationModal.deleteMessage")}
      </ConfirmationModal>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="md"
        scroll="paper"
      >
        <DialogTitle id="form-dialog-title">
          {campaignEditable ? (
            <>
              {campaignId
                ? `${i18n.t("campaigns.dialog.update")}`
                : `${i18n.t("campaigns.dialog.new")}`}
            </>
          ) : (
            <>{`${i18n.t("campaigns.dialog.readonly")}`}</>
          )}
        </DialogTitle>
        <div style={{ display: "none" }}>
          <input
            type="file"
            ref={attachmentFile}
            onChange={(e) => setAttachment(head(e.target.files))}
          />
        </div>
        <Formik
          initialValues={initialFormikValues}
          enableReinitialize={true}
          onSubmit={async (values, actions) => {
            await handleSaveCampaign(values);
            actions.setSubmitting(false);
          }}
        >
          {({ 
            values, 
            errors, 
            touched, 
            isSubmitting, 
            setFieldValue
          }) => (
              <Form>
                <Tabs
                  value={tab}
                  onChange={(e, newValue) => setTab(newValue)}
                  indicatorColor="primary"
                  textColor="primary"
                //  className={classes.tabs}
                >
                  <Tab label="Datos de la Campaña" />
                  <Tab label="Horarios de Envíos" />
                </Tabs>
                <DialogContent className={classes.dialogContent} dividers>
                  <Grid spacing={2} container>
                    {tab === 0 && (
                      <>
                        <Grid xs={12} md={9} item>
                          <Field
                            as={TextField}
                            label={i18n.t("campaigns.dialog.form.name")}
                            name="name"
                            
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            placeholder={i18n.t("campaigns.dialog.form.name")}
                            InputLabelProps={{ shrink: true }}
                            disabled={!campaignEditable}
                          />
                        </Grid>
                        <Grid xs={12} md={3} item>
                          <Field
                            as={TextField}
                            label={i18n.t("campaigns.dialog.form.scheduledAt")}
                            name="scheduledAt"
                            error={
                              touched.scheduledAt &&
                              Boolean(errors.scheduledAt)
                            }
                            helperText={
                              touched.scheduledAt && errors.scheduledAt
                            }
                            variant="outlined"
                            margin="dense"
                            type="datetime-local"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            fullWidth
                            className={classes.textField}
                            disabled={!campaignEditable}
                          />
                        </Grid>
                        <Grid xs={12} md={12} item>
                          <Autocomplete
                            multiple
                            options={whatsapps}
                            getOptionLabel={(option) => option.name}
                            value={selectedWhatsappIds}
                            onChange={(event, newValue) => {
                              setSelectedWhatsappIds(newValue);
                              setFieldValue("whatsappIds", newValue.length > 0 ? newValue.map(w => w.id) : null);
                            }}
                            renderTags={(value, getTagProps) =>
                              value.map((option, index) => (
                                <Chip
                                  key={index}
                                  label={option.name}
                                  {...getTagProps({ index })}
                                />
                              ))
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                label={i18n.t("campaigns.dialog.form.whatsapp")}
                                fullWidth
                                margin="dense"
                                error={touched.whatsappIds && Boolean(errors.whatsappIds)}
                                helperText={touched.whatsappIds && errors.whatsappIds}
                              />
                            )}
                            disabled={!campaignEditable}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <Field
                            as={TextField}
                            label="Intervalo entre mensajes"
                            name="messageInterval"
                            type="number"
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            placeholder="Ejemplo: 20"
                            InputProps={{
                              inputProps: { min: 0 },
                            }}
                            value={values.messageInterval === 0 ? "" : values.messageInterval}
                            disabled={!campaignEditable}
                          />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                          <Field
                            as={TextField}
                            label="Intervalo largo después de"
                            name="longerIntervalAfter"
                            type="number"
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            placeholder="Ejemplo: 50"
                            InputProps={{
                              inputProps: { min: 0 },
                            }}
                            value={values.longerIntervalAfter === 0 ? "" : values.longerIntervalAfter}
                            disabled={!campaignEditable}
                          />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                          <Field
                            as={TextField}
                            label="Mayor intervalo"
                            name="greaterInterval"
                            type="number"
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            placeholder="Ejemplo: 60"
                            InputProps={{
                              inputProps: { min: 0 },
                            }}
                            value={values.greaterInterval === 0 ? "" : values.greaterInterval}
                            disabled={!campaignEditable}
                          />
                        </Grid>
                        <Grid xs={12} md={4} item>
                          <Autocomplete
                            options={contactLists}
                            getOptionLabel={(option) => option.name}
                            value={
                              contactLists.find(
                                (list) => list.id === values.contactListId
                              ) || null
                            }
                            onChange={(event, newValue) => {
                              setFieldValue("contactListId", newValue ? newValue.id : null);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                label={i18n.t("campaigns.dialog.form.contactList")}
                                placeholder={i18n.t("campaigns.dialog.form.contactList")}
                                fullWidth
                                margin="dense"
                                error={touched.contactListId && Boolean(errors.contactListId)}
                                helperText={touched.contactListId && errors.contactListId}
                              />
                            )}
                            disabled={!campaignEditable}
                          />
                        </Grid>
                        <Grid xs={12} md={4} item>
                          <Autocomplete
                            options={queues}
                            getOptionLabel={(option) => `${option.name} - ${option.specification}`}
                            value={
                              queues.find(
                                (queue) => queue.id === values.queueId
                              ) || null
                            }
                            onChange={(event, newValue) => {
                              setFieldValue("queueId", newValue ? newValue.id : null);
                              setShowQueueOptions(newValue && newValue.type === "simple");
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                label={i18n.t("campaigns.dialog.form.queue")}
                                placeholder={i18n.t("campaigns.dialog.form.queue")}
                                fullWidth
                                margin="dense"
                                error={touched.queueId && Boolean(errors.queueId)}
                                helperText={touched.queueId && errors.queueId}
                              />
                            )}
                            disabled={!campaignEditable}
                          />
                        </Grid>
                        {showQueueOptions && (
                          <Grid xs={4} item>
                            <FormControlLabel
                              control={
                                <Field
                                  as={Checkbox}
                                  name="queueOptions"
                                  color="primary"
                                  checked={values.queueOptions}
                                  onChange={(event) => setFieldValue("queueOptions", event.target.checked)}
                                  disabled={!campaignEditable}
                                />
                              }
                              label="Enviar el mensaje con las opciones de la cola seleccionada"
                              labelPlacement="end"
                            />
                          </Grid>
                        )}
                        <Grid xs={12} item>
                          <Tabs
                            value={messageTab}
                            indicatorColor="primary"
                            textColor="primary"
                            onChange={(e, v) => setMessageTab(v)}
                            variant="fullWidth"
                            centered
                            className={classes.tabs}
                          >
                            <Tab label="Msg. 1" index={0} />
                            <Tab label="Msg. 2" index={1} />
                            <Tab label="Msg. 3" index={2} />
                            <Tab label="Msg. 4" index={3} />
                            <Tab label="Msg. 5" index={4} />
                          </Tabs>
                          <Box style={{ paddingTop: 20 }}>
                            {messageTab === 0 && renderMessageField("message1")}
                            {messageTab === 1 && renderMessageField("message2")}
                            {messageTab === 2 && renderMessageField("message3")}
                            {messageTab === 3 && renderMessageField("message4")}
                            {messageTab === 4 && renderMessageField("message5")}
                          </Box>
                        </Grid>
                        {(campaign.mediaPath || attachment) && (
                          <Grid xs={12} item>
                            <Button startIcon={<AttachFileIcon />}>
                              {attachment != null
                                ? attachment.name
                                : campaign.mediaName}
                            </Button>
                            {campaignEditable && (
                              <IconButton
                                onClick={() => setConfirmationOpen(true)}
                                color="secondary"
                              >
                                <DeleteOutlineIcon />
                              </IconButton>
                            )}
                          </Grid>
                        )}
                      </>
                    )}

                    {tab === 1 && (
                      <Grid item xs={12}>
                        <SchedulesForm
                          schedules={values.schedules}
                          setFieldValue={setFieldValue}
                        />
                      </Grid>
                    )}
                  </Grid>
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                  {campaign.status === "CANCELADA" && (
                    <Button
                      color="primary"
                      onClick={() => restartCampaign()}
                      variant="outlined"
                    >
                      {i18n.t("campaigns.dialog.buttons.restart")}
                    </Button>
                  )}
                  {campaign.status === "EM_ANDAMENTO" && (
                    <Button
                      color="primary"
                      onClick={() => cancelCampaign()}
                      variant="outlined"
                    >
                      {i18n.t("campaigns.dialog.buttons.cancel")}
                    </Button>
                  )}
                  {!attachment &&
                    !campaign.mediaPath &&
                    campaignEditable && (
                      <Button
                        color="primary"
                        onClick={() => attachmentFile.current.click()}
                        disabled={isSubmitting}
                        variant="outlined"
                      >
                        {i18n.t("campaigns.dialog.buttons.attach")}
                      </Button>
                    )}
                  <Button
                    onClick={handleClose}
                    color="secondary"
                    disabled={isSubmitting}
                    variant="outlined"
                  >
                    {i18n.t("campaigns.dialog.buttons.close")}
                  </Button>
                  {(campaignEditable || campaign.status === "CANCELADA") && (
                    <Button
                      type="submit"
                      color="primary"
                      disabled={isSubmitting}
                      variant="contained"
                      className={classes.btnWrapper}
                    >
                      {campaignId
                        ? `${i18n.t("campaigns.dialog.buttons.edit")}`
                        : `${i18n.t("campaigns.dialog.buttons.add")}`}
                      {isSubmitting && (
                        <CircularProgress
                          size={24}
                          className={classes.buttonProgress}
                        />
                      )}
                    </Button>
                  )}
                </DialogActions>
              </Form>
            )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default CampaignModal;
